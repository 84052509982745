import './App.css'; 
import { Button, ButtonToolbar, ButtonGroup, Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Web3 from 'web3';
import axios from 'axios';
import React, { Component } from 'react';
import Web3Modal from 'web3modal';
import WalletConnectProvider from '@walletconnect/web3-provider';
import CoinbaseWalletSDK from '@coinbase/wallet-sdk';
import Footer  from './footer/Footer';
import CLAIMABI from './abi/claim.json';


    var account = null;
    var claimContract = null;
    var web3 = null;
    
    const outvalue ="1"
    const  CLAIMADDRESS = "0xEe1f179228684C29Bc8E0535C00e240f7bAF7eF2"
    const apikey = "A5ICEHRKQEKWQZJIDKHJV9WBM86QH49I3H";
    const endpoint = "https://api-testnet.snowtrace.io/api";
    
class Stake extends Component {

  
  render() {  

  return (
    <div className="App nftapp">
        <div className='container container-style'>
          <div className='col'>
            <body className='rvrsCard'>
          <form>
            <div className="row">
		      <div className="col">
		  <div className="col mb-4">
        <div className="box">
        <h1 style={{fontSize:"16",fontWeight:"16"}}>RVRS Dashboard</h1>
        </div>
		  </div>
		</div>
		</div>
		<div className='row'>
		<div className='col xs={6} md={4}'>
		</div>
		</div>
    <div className="row mt-2">
      <div className='col'>
        <h7 class="mintAmount mt-1">
        <p>Tokens available to claim:</p></h7>
        </div>
        <div className='col'>
        <div className='px-1'>
            
    </div>
    </div>
    </div>  
    <div className="row mt-2">
		  
         </div>
        </form>
          </body>
          </div>
</div>
<Footer />
        </div>
      
   );
  };
}

export default Stake;
