import React from "react";
import { Box, Container, Row, Column, FooterLink } from "./FooterStyle";
import { FaTwitter, FaDiscord, FaGithub, FaMoneyBillAlt,  FaBook, FaVoteYea, FaClipboard,  FaMedium } from 'react-icons/fa';
import './Footer.css';




const Footer = () => {
return (
    <div className="footer">
	<div className="regular-footer">
	<Box>
	<Container>
		<Row>
		<Column>
			<FooterLink href="https://docs.google.com/spreadsheets/d/1fNsmVWqtPrtZr7z4i2n1ZgRNAEZdX3coPzMbZNPCZ34/edit#gid=563760963"> Treasury </FooterLink>
			<FooterLink href="https://snapshot.org/#/rvrsprotocol.eth"> Governance </FooterLink>
		</Column>
		<Column>
			
			<FooterLink href="https://github.com/ReverseProtocol/"> GitHub </FooterLink>
			<FooterLink href="https://reverse.gitbook.io/docs/Gitbook"> GitBook </FooterLink>
		</Column>
		<Column>
			
			<FooterLink href="https://twitter.com/RVRSProtocol"> Twitter </FooterLink>
			<FooterLink href="https://discord.gg/bDSuzfybCU"> Discord </FooterLink>
		</Column>
		<Column>
			<FooterLink href="https://medium.com/@reverseprotocolONE"> Medium </FooterLink>
			<FooterLink href="https://rvrs.app/"> Home </FooterLink>
		</Column>
		</Row>
	</Container>
	</Box>
	</div>
	
	<div className="mobile-footer" id="mobile-footer">
    <Box>
	<Container>
		<Row>
		<Column>
			<FooterLink href="https://docs.google.com/spreadsheets/d/1Ocl8Gx8rA4Zbzcsxb8-EuFNEQNlTsMLsMMRazixz_gk/edit#gid=0"><FaClipboard /></FooterLink>
			<FooterLink href="https://gov.harmony.one/#/reverse"><FaVoteYea /></FooterLink>
		</Column>
		<Column>
			
			<FooterLink href="https://github.com/ReverseProtocol/"><FaGithub /></FooterLink>
			<FooterLink href="https://reverse.gitbook.io/docs/Gitbook"><FaBook /></FooterLink>
		</Column>
		<Column>
			<FooterLink href="https://twitter.com/RVRSProtocol"><FaTwitter /></FooterLink>
			<FooterLink href="https://discord.gg/bDSuzfybCU"><FaDiscord /></FooterLink>
		</Column>
		<Column>
			<FooterLink href="https://medium.com/@reverseprotocolONE"><FaMedium /></FooterLink>
			<FooterLink href="https://app.sushi.com/swap?outputCurrency=0xed0b4b0f0e2c17646682fc98ace09feb99af3ade"><FaMoneyBillAlt /></FooterLink>
		</Column>
		</Row>
	</Container>
	</Box>
    </div>
    </div>
);
};
export default Footer;
