import './App.css'; 
import { Button} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Web3 from 'web3';
import axios from 'axios';
import React, { Component } from 'react';
import Footer  from './footer/Footer';
import {claim, getRVRSBalanceInClaim} from "../helpers/web3helper";
import {getClaimable} from "../helpers/web3helper";
import { Timeline } from 'react-twitter-widgets'
       
   
class App extends Component {

  constructor() {
    super();
    this.state = {
      claimable: [],
      price: [],
      RVRSBalanceInClaim: [],
    };
   } 

  async componentDidMount() {
    var claimableRVRS = await getClaimable();
    this.setState({claimable: claimableRVRS});    
    document.getElementById('claimable-tokens').textContent = 0;

    var unclaimedRVRS = (await getRVRSBalanceInClaim()/1E18).toFixed(2);
    this.setState({RVRSBalanceInClaim: unclaimedRVRS});   
        
 }

  render() {
  
     var {price} = this.state; 
     var claimable = 0;
     var{RVRSBalanceInClaim} =this.state;
     
  return (
    <div className="App nftapp">
        <div className='container container-style'>
          <div className='col'>
            <body className='rvrsCard'>
          <form>
            <div className="row">
		      <div className="col">
		  <div className="col mb-4">
        <div className="box">
	<h1 style={{fontSize:"12",fontWeight:"16"}}>Thank you Reversoooors</h1>
	<h7><p>Please use Bond to exit</p></h7>
        </div>
		  </div>
		</div>
		</div>
		<div className='row'>
		<div className='col xs={6} md={4}'>
		</div>
		</div>
    <div className="row mt-2">
      <div className='col'>
        <h7 class="mintAmount mt-1">
        <p>Tokens available to claim:</p></h7>
        <div className="claimable-view" id='claimable-tokens' style={{
			  color: "#00000", 
			  textShadow:"1px 1px 1px black",
			  fontSize:"17px"
			  }}>
        <label for="floatingInput">Connect Wallet</label>
        </div>
        </div>
        <div className='col'>
        <h7 class="mintAmount mt-1">
        <p>Total Unclaimed RVRS</p></h7>
        <div className='px-1'>
            <div className="claimable-view" id='claimable-tokens'  style={{
			  color: "#00000", 
			  textShadow:"1px 1px 1px black",
			  fontSize:"17px"
			  }}>
      	{RVRSBalanceInClaim}
		</div>
    </div>
    </div>
    </div>  
    <div className="row mt-2">
		<div className="col xs={10} md={8}">
		<div className="btn-ded">
		  <button className="btn" onClick={claim}>{this.state.claimable >0 ? "Claim" : "None Claimable"}</button>
		</div>  
		</div>
         </div>
        </form>
          </body>
          </div>
<div className="twitterFeed">
		<Timeline
  dataSource={{
    sourceType: 'profile',
    screenName: 'RVRSProtocol'
  }}
  options={{
    height: '300'
  }}
/></div>
</div>
<Footer />
        </div>
      
  );
};
}

export default App;
