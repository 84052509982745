import React, { Component } from 'react';
import "./Navbar.css"
import { FaBars, FaTimes } from 'react-icons/fa';
import {getAddress, init, getNetwork} from "../helpers/web3helper";
import { MenuData } from './Menu'
import Web3 from 'web3';
import Web3Modal from 'web3modal';
import WalletConnectProvider from '@walletconnect/web3-provider';
import {Addresses , NetworkId, CurrentNetwork} from '../constants/addresses.js';
import CoinbaseWalletSDK from '@coinbase/wallet-sdk';



class NavBar extends Component {
	
	constructor(props) { 
		super(props);
		this.state = {isConnected : [], isConnection: [], isOpen: [], network: []};
		this.connectWallet = this.connectWallet.bind(this);
		this.dropDownToggle = this.dropDownToggle.bind(this);
	}
	
	dropDownToggle() { 
		this.setState(prevState => ({ 
			isOpen: !prevState.isOpen
		}));
	}
	
	connectionSuccess() { 
		this.setState(prevState => ({
			isConnection: !prevState.isConnection
		}));
	}
	
	async connectNetwork(){
		var network = await getNetwork();
		this.setState({network : network})
		this.setState({targetNetwork: CurrentNetwork[0]})
		}

	async connectWallet() {
		var account = await getAddress();
		this.setState({account:"0x.."+account.substring(38,50)});
		this.connectNetwork();
		this.setState(prevState => ({ 
		isConnected: !prevState.isConnected
		}));
	}
	
		/* componentDidMount(){
				this.loadAccounts();
			} */



async componentDidMount(
	
){this.connectWallet()
}
  
  render() {
	var{network}=this.state
	var{targetNetwork}=this.state
	var{isConnected}=this.state
	var{account}=this.state 
	return ( 

	
	<>
	 <nav className="Navbar">
	     <div className="menuIcon">
       <button className="dropbtn" onClick={this.dropDownToggle}>
		{this.state.isOpen ?  <FaTimes /> : <FaBars />}
	     </button>
       </div>
      <ul className={this.state.isOpen ? "menu active" : "menu"}>
         {MenuData.map((item, index) => {
         return(
         <li>
            <a href={item.url} className={item.cName}>
              {item.title}</a>
         </li>
         );
        })}
       </ul>
       
	    
	    <div className="centerBanner">
	   <img className="reverseBanner" alt="=[" src="ReverseWhiteUpAltShadow.png" />
	    </div>
	    <div className="rightSide">
              
		{/* <div className="connect-btn">{(network === targetNetwork) ? "Arbitrum ":"Connect to Arbitrum"}</div> */}
		
		<div classsName="correct-network" style={{display: (network === targetNetwork) ? "block":"none"}}> <h7>Arbitrum</h7> </div>
		<div className="wrong-network" style={{display: (network === targetNetwork) ? "none":"block"
		}}> <h7>Wrong Network</h7> </div>
		
		<button className="connect-btn"   onClick={this.connectWallet}>
		{isConnected ? "Connect" : account}
		</button>
	    </div>
	</nav>
	</>
  );
};
}


export default NavBar;
