export const MenuData = [
  {
    title: 'Claim',
    url: '/',
    cName: 'nav-links',
    icon: 'FaHome'
  },
  {
    title: 'Bond',
    url: '/bond',
    cName: 'nav-links',
    icon: 'FaBars'
  },
]
