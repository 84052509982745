import Web3 from 'web3';
import Web3Modal from 'web3modal';
import WalletConnectProvider from '@walletconnect/web3-provider';
import {Addresses , NetworkId, CurrentNetwork} from '../constants/addresses.js';
import CLAIMABI from '../component/abi/claim.json';
import BONDABI from '../component/abi/rvrsbond.json';
import RVRSABI from '../component/abi/rvrstoken.json';
import CoinbaseWalletSDK from '@coinbase/wallet-sdk';
import ERC20_ABI from '../component/abi/erc20.json';


let account, claimContract, bondContract, rvrsContract, provider, stableContract, network;
let isInitialized=false;
const CLAIMADDRESS = Addresses[CurrentNetwork].CLAIMADDRESS;
export const BONDADDRESS = Addresses[CurrentNetwork].BONDADDRESS;
export const RVRSADDRESS = Addresses[CurrentNetwork].RVRSADDRESS;
export const STABLECOINADDRESS = Addresses[CurrentNetwork].STABLECOINADDRESS;
export const stableDecimals = 1E6; //have to change this for USDC as 6 decimals 

/* global BigInt */  
const providerOptions = {
    walletconnect: {
      package: WalletConnectProvider, // required
      options: {
        infuraId: "eX3cNYPfrM6C8Y90BxsFsiyRsuEAnXuplRxqaN87" // required
      }
   }, 
     coinbasewallet: {
      package: CoinbaseWalletSDK, // Required
      options: {
        appName: "RVRS APP", // Required
        infuraId: "eX3cNYPfrM6C8Y90BxsFsiyRsuEAnXuplRxqaN87", // Required
        rpc: "", // Optional if `infuraId` is provided; otherwise it's required
        chainId: 42161, // Optional. It defaults to 1 if not provided
        darkMode: true // Optional. Use dark theme, defaults to false
      }
    },
  };


export async function init(){

    const web3Modal = new Web3Modal({
        network: "arbitrum", // optional
        theme: "dark",
        cacheProvider: true, // optional
        providerOptions // required
      });
    
    //set up the wallet functions
    provider = await web3Modal.connect();
    const web3 = new Web3(provider);
    await provider.send('eth_requestAccounts');
    let accounts = await web3.eth.getAccounts();
    account = accounts[0];
    
    //instantiate the contracts 
    claimContract = new web3.eth.Contract(CLAIMABI, CLAIMADDRESS);
    rvrsContract = new web3.eth.Contract(RVRSABI, RVRSADDRESS);
    bondContract = new web3.eth.Contract(BONDABI, BONDADDRESS);
    stableContract = new web3.eth.Contract(ERC20_ABI,STABLECOINADDRESS);

    //get network ID 
    network = web3.eth.net.getId()
    provider.on('chainChanged', () => {
        document.location.reload()
     })
    //initialise everything
    isInitialized = true;
 }  
 
 //do all the web3 functions here 
export async function getClaimable(){
    if(!isInitialized){
        await init();
    }
    return claimContract.methods.claimable(account).call();
}

export async function getAddress() {
    if(!isInitialized){
        await init();
    }
    return account;
}

export async function claim(e) {
    if(!isInitialized){
        await init();
    }
        e.preventDefault();
        var claimable = await getClaimable();
        if(claimable > 0){
        claimContract.methods.claim(account).send({from: account})
        }
}

export async function getRVRSBalance(){
    if(!isInitialized){
        await init();
    }
    return rvrsContract.methods.balanceOf(account).call();
}

export async function getExchangeRate(){
    if(!isInitialized){
        await init();
    }
    return bondContract.methods.exchangeRate().call();
}

export async function bond(amount_in){
    if(!isInitialized){
        await init();
    }
        bondContract.methods.bond(amount_in).send({from: account})
}

export async function getStableBalance(_address){
    if(!isInitialized){
        await init();
    }
    return stableContract.methods.balanceOf(_address).call();
}

export async function getRVRSBalanceInClaim(){
    if(!isInitialized){
        await init();
    }
    return rvrsContract.methods.balanceOf(CLAIMADDRESS).call();
}

export async function getRVRSBondAllowance(){
    if(!isInitialized){
        await init();
    }
    return  rvrsContract.methods.allowance(account,BONDADDRESS).call();
}

export async function addRVRSBondAllowance(e){
    if(!isInitialized){
        await init();
    }
    e.preventDefault();
    rvrsContract.methods.approve(BONDADDRESS,BigInt(1E26)).send({from: account});
}

export async function checkBondAllowance(){
    if(!isInitialized){
        await init();
    }
    var allowance = BigInt(await getRVRSBondAllowance());
    var balance = BigInt(await getRVRSBalance());
    return (allowance > balance); 
}

export async function getNetwork(){
    if(!isInitialized){
        await init();
    }
    return network
}
