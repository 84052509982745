import './App.css'; 
import { Button, ButtonToolbar, ButtonGroup, Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Web3 from 'web3';
import axios from 'axios';
import React, { Component } from 'react';
import Web3Modal from 'web3modal';
import WalletConnectProvider from '@walletconnect/web3-provider';
import CoinbaseWalletSDK from '@coinbase/wallet-sdk';
import Footer  from './footer/Footer';
import CLAIMABI from './abi/claim.json';


    var account = null;
    var claimContract = null;
    var web3 = null;
    
    const outvalue ="1"
    const  CLAIMADDRESS = "0xEe1f179228684C29Bc8E0535C00e240f7bAF7eF2"
    const apikey = "A5ICEHRKQEKWQZJIDKHJV9WBM86QH49I3H";
    const endpoint = "https://api-testnet.snowtrace.io/api";
    
    const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider, // required
    options: {
      infuraId: "eX3cNYPfrM6C8Y90BxsFsiyRsuEAnXuplRxqaN87" // required
    }
 }, 
   coinbasewallet: {
    package: CoinbaseWalletSDK, // Required
    options: {
      appName: "Switch NFT Staking", // Required
      infuraId: "eX3cNYPfrM6C8Y90BxsFsiyRsuEAnXuplRxqaN87", // Required
      rpc: "", // Optional if `infuraId` is provided; otherwise it's required
      chainId: 4, // Optional. It defaults to 1 if not provided
      darkMode: true // Optional. Use dark theme, defaults to false
    }
  },
};

const web3Modal = new Web3Modal({
  network: "rinkerby", // optional
  theme: "dark",
  cacheProvider: true, // optional
  providerOptions // required
});
    
class Stake extends Component {
	
  
  render() {
  
 {/* if (web3Modal.cachedProvider) {
  await web3Modal.connect();
} */}
	
async function connectwallet() {
      var provider = await web3Modal.connect();
      var web3 = new Web3(provider);
      await provider.send('eth_requestAccounts');
      var accounts = await web3.eth.getAccounts();
      account = accounts[0];
      document.getElementById('wallet-address').textContent = "0x..." + account.substring(34, 50);
      claimContract = new web3.eth.Contract(CLAIMABI, CLAIMADDRESS);
      claimable = Number(await claimContract.methods.claimable(account).call());
      document.getElementById('claimable-tokens').textContent = (claimable * 0.000000000000000001).toFixed(2);
      }
     
    
async function claim() {
        var _user = account;
      claimContract.methods.claim(_user).send({from: account});
      }
      
async function claimable() {
	Number(await claimContract.methods.claimable(account).call());
}
      
   

  return (
    <div className="App nftapp">
        <div className='container container-style'>
          <div className='col'>
            <body className='rvrsCard'>
          <form>
            <div className="row">
		      <div className="col">
		  <div className="col mb-4">
        <div className="box">
        <h1 style={{fontSize:"16",fontWeight:"16"}}>Claim RVRS</h1>
        </div>
		  </div>
		</div>
		</div>
		<div className='row'>
		<div className='col xs={6} md={4}'>
		</div>
		</div>
    <div className="row mt-2">
      <div className='col'>
        <h7 class="mintAmount mt-1">
        <p>Tokens available to claim:</p></h7>
        </div>
        <div className='col'>
        <div className='px-1'>
            <div className="claimable-view" id='claimable-tokens' style={{
			  color: "#00000", 
			  textShadow:"1px 1px 1px black",
			  fontSize:"17px"
			  }}>
      	<label for="floatingInput">Please Connect Wallet</label>
		</div>
    </div>
    </div>
    </div>  
    <div className="row mt-2">
		<div className="col xs={10} md={8}">
		  <Button variant="light" style={{marginRight:"2px", border:"1"}} onClick={claim}>
			  <h6 style={{fontFamily:"", fontWeight:"300", fontSize:"18px"}}>Claim</h6>
		 </Button>
		</div>  
         </div>
        </form>
          </body>
          </div>
</div>
<Footer />
        </div>
      
  );
};
}

export default Stake;
