import './App.css'; 
import { Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Web3 from 'web3';
import axios from 'axios';
import React, { Component } from 'react';
import Footer  from './footer/Footer';
import {getRVRSBalance, getExchangeRate, getStableBalance, bond, BONDADDRESS, stableDecimals, addRVRSBondAllowance, checkBondAllowance,} from "../helpers/web3helper";

/* global BigInt */    
    
    //these wont work on other networks - will need to fix.  Snowtrace Contants.  
    const endpoint = "https://api-testnet.snowtrace.io/api";
    const dexpairendpoint = "https://api.dexscreener.com/latest/dex/pairs/avalanche/";
    const RVRSAVAXPAIR = "0xf4003f4efbe8691b60249e6afbd307abe7758adb";
    const apikey = "A5ICEHRKQEKWQZJIDKHJV9WBM86QH49I3H";

    
    
class Bond extends Component {

  constructor() {
      super();
      this.state = {
        balance: [],
        price: [],
        exchangeRateRVRS: [],
        amountToBond: [],
        stableBalanceInBondContract:[],
        isApproved:[],
      };
      
      this.handleChange = this.handleChange.bind(this);
      this.useBond = this.useBond.bind(this);
    } 
  
    async componentDidMount() {
    
    var balanceRVRS = await getRVRSBalance();
    this.setState({balance: balanceRVRS}); 
    document.getElementById('token-balance').textContent = (balanceRVRS * 0.000000000000000001).toFixed(2);
   
    var exchangeRate = ((await getExchangeRate())*(1/stableDecimals)).toFixed(3);
    this.setState({exchangeRateRVRS: exchangeRate});
    
    var stableBalanceInBondContract = (await getStableBalance(BONDADDRESS))/stableDecimals;
    this.setState({stableBalanceInBondContract: stableBalanceInBondContract});

    var isApproved = await checkBondAllowance();
    this.setState({isApproved: isApproved});
     }
    
    handleChange(event){
      this.setState({amountToBond: event.target.value})
    }

    async useBond(e){
    e.preventDefault();
    const amount_in= BigInt(this.state.amountToBond*1E18);
        await bond(amount_in);
   }

    render() {
    var {price} = this.state;
    var {volume} = this.state;
    var {exchangeRateRVRS} = this.state;
    var {stableBalanceInBondContract} = this.state;
    var {isApproved} = this.state;
    
    
  return (
    <div className="App nftapp">
      <div className='container container-style'>
          <div className='col'>
            <body className='rvrsCard'>
              <form>
              
                <div className="row">
		              <div className="col">
		                <div className="col mb-4">
                      <div className="box">
                        <h1 style={{fontSize:"16",fontWeight:"16"}}>Bond RVRS</h1>
                      </div>
		                </div>
		              </div>
		            </div>
                <div className="row"><div className="col"> Note: Only bond RVRS if you want to cash out - it is permanent.</div></div>
                <br/>
		            <div className='row'>
		                <div className='col xs={6} md={4}'>
		                </div>
		              </div>
                  <div className="row mt-2">
                    <div className='col'>
                      <h7 class="mintAmount mt-1">
                      <p>Balance:</p></h7>
                      <div className="claimable-view" id='token-balance' style={{
			                  color: "#00000", 
                        textShadow:"1px 1px 1px black",
                        fontSize:"17px"
                        }}> 
                        <label for="floatingInput">Please Connect Wallet</label>
                      </div>
        <br/>
        </div>
        
        <div className='col'>
        <h7 class="mintAmount mt-1">
        <p>Price:</p></h7>
        <div className='px-1'>
            <p> ${exchangeRateRVRS} </p>
    </div>
    </div>
    </div>
    
    <div className='row'>
		      <div className='col xs={6} md={4}'>
            <form>        <label>
          Amount to Bond:
          <br/>
          <input type="text" value={this.state.amountToBond} onChange={this.handleChange} size="10" />        </label>
          <br/>
        <div className="col xs={10} md={8}">
        <div className="btn-bond">
            <button className="btn" onClick={this.useBond}>Exchange</button>
            <button className="btn" onClick={addRVRSBondAllowance}>Approve</button>
	</div>
            
	</div>  
      </form>
      
      
        </div>
        </div>
        <br/>  
    <div className="row mt-2">
		<div className='col'>
        <h7 class="mintAmount mt-1">
        <p>Current USDC Available for Exiters</p></h7>
        <div className='px-1'>
            <p> ${stableBalanceInBondContract} </p>
            </div> 
            </div>
            </div>
                        </form>
          </body>
          
<Footer />
      </div>
      </div>
		  </div>
		                
);
};
}

export default Bond;
