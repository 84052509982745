import 'bootstrap/dist/css/bootstrap.min.css';
import Web3 from 'web3';
import React, { Component } from 'react';
import NavBar from './component/Navbar'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './component/Home';
import Bond from './component/Bond';
import Stake from './component/Stake';
import Footer  from './component/footer/Footer';
import Dashboard from './component/Dash';

class App extends Component {

	render() {

return (
    <div className="App nftapp">
        <Router>
        <NavBar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/bond' element={<Bond />} />
          <Route path='/staking' element={<Stake />} />
          <Route path='/dash' element={<Dashboard />} />
        </Routes>
        </Router>      
        <Footer />
    </div>
  );
};
}

export default App;
