import {} from "../helpers/web3helper";

export const NetworkId = {
    RINKEBY: 4, 
    GOERLI: 5, 
    ARBITRUM: 42161,
    POLYGON: 137,
}

export const Addresses = {
    [NetworkId.RINKEBY]:
    {
        RVRSADDRESS:"0x5C9C31426ADF116FD28427D622339EBF9EA158b6", 
        BONDADDRESS: "0x53b90ac517636C22F42b6D43c924Cb9C9c0758fd",
        CLAIMADDRESS:"0xEe1f179228684C29Bc8E0535C00e240f7bAF7eF2",
        STABLECOINADDRESS: "0xc3afeaaad6830d42938fc4128dd06cbf01470a2c", //in Rinkeby this is stable test, on mainnet we're going to make this USDC
    },
    [NetworkId.ARBITRUM]:
    {
        RVRSADDRESS:"0x6b50fBE8852E19799563d9EaD550EA44Cb9B7975",
        BONDADDRESS: "0xF86Ca8a05C832bDD3aB0cf1dD2EdC07E7b4dc23c",
        CLAIMADDRESS: "0x33A4951Cb21f12ea4204EB1ed8AcE6429056162F",
        STABLECOINADDRESS: "0xff970a61a04b1ca14834a43f5de4533ebddb5cc8",
    }
}

export const CurrentNetwork = [NetworkId.ARBITRUM];